import React, { Component, useState, useEffect } from 'react'
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import { connect } from 'react-redux';
import { ruleenginAction } from "../../../../../state/ducks/ruleengine";
import Loaders from '../../../common/loader/Loader';
import { AgGrid } from '../../../../ui/AgGridTable/AgGrid';
import CardTemplate from '../../../../ui/cards/SimpleCard/CardTemplate'
import Modal from 'react-bootstrap/Modal';
function RuleEngineDetail(props) {

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Rule Name", field: "name", width: 150,
        },
        {
            headerName: "Description", field: "description", width: 275,
        },
        {
            headerName: "Action Name", field: "actionName", width: 150
        },
        {
            headerName: "Form Name", field: "form.name", width: 150
        },
        {
            headerName: "Target Form", field: "targetForm.name", width: 150
        },
        {
            headerName: "Target Question", field: "targetQuestion.name", width: 350
        },
        {
            headerName: "Delete",
            field: "delete",
            template:
                `
            <button className="btn-delete"><i class="fas fa-trash-alt"></i></button>
            `
            , width: 80
        }


    ]);

    const [rowData, setRowData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [ruleToDelete, setRuleToDelete] = useState(false);
    useEffect(() => {
        if (props.ruleEngineList !== undefined) {
            setRowData(props.ruleEngineList.fields)
        }
    }, [props.ruleEngineList])

    useEffect(() => {
        getAllForms();
    }, []);

    useEffect(() => {
        console.log("RULE DELETE :: ", props.deleteRule)
        getAllForms();
    }, [props.deleteRule]);

    async function getAllForms() {
        await props.getAllFieldRule();
    }


    function onRowSelected(event) {
        console.log('onRowSelected: ' + event.node.data);
    };

    async function onCellClicked(event) {
        if (event.column.colId === "delete") {
            setShowModal(true)
            setRuleToDelete(event.data["uuid"])
            console.log("data ::", event.data)
        }
    };

    async function handleDeleteAction() {
        await props.deleteRuleByUUID(ruleToDelete)
        setShowModal(false)
    }

    function nextStep() {
        props.nextStep();
    }

    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        window.onresize = () => {
            this.gridApi.sizeColumnsToFit();
        }
    }

    if (props.isloading) return <Loaders />;
    return (
        <>
            <CardTemplate
                title="Rule Engine"
                action={
                    <button className="service-btn btn btn-primary " onClick={nextStep}><i class="fas fa-plus"></i>  Create New</button>
                }
            >
                <div className="card-body rm-paadding">
                    <AgGrid
                        onGridReady={onGridReady}
                        columnDefs={columnDefs}
                        onRowSelected={onRowSelected}
                        rowData={rowData}
                        onCellClicked={onCellClicked}
                    />
                </div>
            </CardTemplate>

            <Modal show={showModal} onHide={() => { if (showModal) { setShowModal(false) } }} style={{ marginTop: '40px' }} >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Rule?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div style={{ textAlign: "center" }}>
                            <p>This will delete the rule permanently, please confirm your action</p>
                            <button
                                name="submit"
                                type="button"
                                onClick={handleDeleteAction}
                                style={{ marginTop: "20px", float: "right" }}
                                className="btn btn-warning"
                            >
                                Confirm Delete
                            </button>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>

    )
}

const mapStateToProps = state => ({
    ruleEngineList: state.ruleengine.rules,
    isloading: state.ruleengine.loading,
    deleteRule: state.ruleengine.deleteRule
});

const mapDispatchToProps = {
    getAllFieldRule: ruleenginAction.getFieldRule,
    deleteRuleByUUID: ruleenginAction.deleteRuleByUUID
};


export default connect(mapStateToProps, mapDispatchToProps)(RuleEngineDetail) 
