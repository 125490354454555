import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList({
  leftList,
  rightList,
  leftAction,
  rightAction,
  isUpdateLoading,
  isLeftLoading,
  isRightLoading,
}) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(leftList);
  const [right, setRight] = React.useState(rightList);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    setChecked([value]);
  };

  const handleCheckedRight = () => {
    leftAction(checked[0].uuid);
  };

  const handleCheckedLeft = () => {
    rightAction(checked[0].uuid);
  };

  const customList = (isLeft, items) => (
    <Paper className={classes.paper}>
      {isLeft && isLeftLoading && !isUpdateLoading && <LinearProgress />}
      {!isLeft && isRightLoading && !isUpdateLoading && <LinearProgress />}

      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.uuid}-label`;

          let isShowWorkflow = true;

          if (isLeft) {
            const workflow = rightList.find(
              (item) => item.workflow.uuid === value.uuid
            );

            if (workflow) isShowWorkflow = false;
          }

          if (isShowWorkflow) {
            return (
              <ListItem
                key={value.uuid}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${
                    value?.workflow ? value.workflow.name : value.name
                  }`}
                />
              </ListItem>
            );
          }
        })}
        <ListItem />
      </List>
    </Paper>
  );

  useEffect(() => {
    setLeft(leftList);
  }, [leftList]);

  useEffect(() => {
    setRight(rightList);
  }, [rightList]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList(true, left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          {isUpdateLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0 || isLeftLoading}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0 || isRightLoading}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item>{customList(false, right)}</Grid>
    </Grid>
  );
}
