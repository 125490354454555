import { authenticationGenerator } from '../utilities/helpers';
import { history } from '../history';
import { BASE_URL } from '../utilities/constants/globalconstants'

export const userService = {
    logInService,
    logOutService,
    registrationService,
    addProviderService,
    searchUser,
    getUser,
    getUserWorkflows,
    addUserWorkflow,
    removeUserWorkflow
};

function logInService(username, password) {
    const token = authenticationGenerator.generateAuthenticationToken(username, password);
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    };

    return fetch(`${BASE_URL}/user?v=full&q=${username}`, requestOptions)
        .then(handleResponseLogin)
        .then(user => {
            if (user === 'authorized') {
                localStorage.setItem('username', username);
                localStorage.setItem('password', password);
            }
            return user;
        });


}

function registrationService(userJSON) {
    const token = authenticationGenerator.generateAuthenticationToken(localStorage.getItem('username'),
        localStorage.getItem('password'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body: JSON.stringify(userJSON)
    };

    return fetch('/user', requestOptions).then(handleResponse);
}

function addProviderService(provider) {
    const token = authenticationGenerator.generateAuthenticationToken(localStorage.getItem('username'),
        localStorage.getItem('password'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body: JSON.stringify(provider)
    };

    return fetch('/provider', requestOptions).then(handleResponse)
}

function logOutService() {
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    localStorage.removeItem('activeTab');
}

function searchUser(text) {
    const token = authenticationGenerator.generateAuthenticationToken(localStorage.getItem('username'),
        localStorage.getItem('password'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    };
    return fetch(`${BASE_URL}/user?q=${text}&v=custom:(display,uuid)`, requestOptions)
        .then(handleResponse).then(response => {
            return response;
        }).catch(err => {
            // console.log("Error :: ", err)
        });
}

function getUser(uuid) {
    const token = authenticationGenerator.generateAuthenticationToken(localStorage.getItem('username'),
        localStorage.getItem('password'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    };
    return fetch(`${BASE_URL}/user/${uuid}?v=custom:(username,uuid,person:(display))`, requestOptions)
        .then(handleResponse).then(response => {
            return response;
        }).catch(err => {
            // console.log("Error :: ", err)
        });
}

function getUserWorkflows(uuid) {
    const token = authenticationGenerator.generateAuthenticationToken(localStorage.getItem('username'),
        localStorage.getItem('password'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    };
    return fetch(`${BASE_URL}/hydra/userworkflow?q=${uuid}&v=custom:(uuid,retired,workflow:(uuid,name),user:(uuid,display))&includeRetired=false`, requestOptions)
        .then(handleResponse).then(response => {
            return response;
        }).catch(err => {
            // console.log("Error :: ", err)
        });
}

function addUserWorkflow(payload) {
    const token = authenticationGenerator.generateAuthenticationToken(localStorage.getItem('username'),
        localStorage.getItem('password'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body: JSON.stringify(payload)
    };

    return fetch(`${BASE_URL}/hydra/userworkflow`, requestOptions).then(handleResponse)
}

function removeUserWorkflow(uuid) {
    const token = authenticationGenerator.generateAuthenticationToken(localStorage.getItem('username'),
        localStorage.getItem('password'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body: JSON.stringify({ retired: true })
    };

    return fetch(`${BASE_URL}/hydra/userworkflow/${uuid}`, requestOptions).then(handleResponse)
}

function handleResponseLogin(response) {
    return response.text().then(text => {
        if (!response.ok) {
            if (response.status === 401) {
                logOutService();
            }
            return 'unauthorized';
        }
        return 'authorized';
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        // console.log("userResp error:: ", JSON.stringify(text) )
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logOutService();
                alert('Session expired! Please login again');
                history.push('/login');
            }

            const error = (data && data.error.message);
            // console.log("userResp error:: ", JSON.stringify(data) )
            return Promise.reject(error);

        }
        // console.log("userResp :: ",data)
        return data;
    });
}