export const GET_ALL_ENCOUNTER = "GET_ALL_ENCOUNTER";
export const CREATE_ENCOUNTER = "CREATE_ENCOUNTER";
export const UPDATE_ENCOUNTER = "UPDATE_ENCOUNTER";
export const DELETE_ENCOUNTER = "ENCOUNTER_DELETE";
export const ADD_TO_ENCOUNTER = "ADD_TO_ENCOUNTER";
export const ACTIVE_ENCOUNTER = "ACTIVE_ENCOUNTER";


export const GET_ALL_ENCOUNTER_TYPE = "GET_ALL_ENCOUNTER_TYPE";
export const CREATE_ENCOUNTER_TYPE  = "CREATE_ENCOUNTER_TYPE ";
export const SEARCH_ENCOUNTER_TYPE  = "SEARCH_ENCOUNTER_TYPE ";
export const API = "API";
export const SET_PROJECT = "SET_PROJECT";