export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',
    REGISTRATION_SUCCESS: 'USER_REGISTRATION_SUCCESS',
    REGISTRATION_FAILURE: 'USER_REGISTRATION_FAILURE',
    REGISTRATION_REQUEST: 'USER_REGISTRATION-REQUEST'

};
