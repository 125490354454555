export const GET_ALL_SERVICE = "GET_ALL_SERVICE";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "SERVICE_DELETE";
export const ADD_TO_SERVICE = "ADD_TO_SERVICE";
export const ACTIVE_SERVICE = "ACTIVE_SERVICE";

export const GET_ALL_SERVICE_TYPE = "GET_ALL_SERVICE_TYPE";
export const CREATE_SERVICE_TYPE = "CREATE_SERVICE_TYPE";
export const UPDATE_SERVICE_TYPE = "UPDATE_SERVICE_TYPE";
export const DELETE_SERVICE_TYPE = "SERVICE_DELETE_TYPE";
export const ADD_TO_SERVICE_TYPE = "ADD_TO_SERVICE_TYPE";
export const ACTIVE_SERVICE_TYPE = "ACTIVE_SERVICE_TYPE";

export const API = "API";
export const SET_PROJECT = "SET_PROJECT";