export const GET_ALL_PHASE = "GET_ALL_PHASE";
export const CREATE_PHASE = "CREATE_PHASE";
export const UPDATE_PHASE = "UPDATE_PHASE";
export const DELETE_PHASE = "PHASE_DELETE";
export const ADD_TO_PHASE = "ADD_TO_PHASE";
export const ACTIVE_PHASE = "ACTIVE_PHASE";
export const CREATE_WORKFLOW_PHASE = "CREATE_WORKFLOW_PHASE";
export const GET_WORKFLOW_PHASE = "GET_WORKFLOW_PHASE";
export const DELETE_WORKFLOW_PHASE = "DELETE_WORKFLOW_PHASE";
export const GET_WORKFLOW_PHASE_BY_WORKFLOW = "GET_WORKFLOW_PHASE_BY_WORKFLOW"

export const API = "API";
export const SET_PROJECT = "SET_PROJECT";
