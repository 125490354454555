export const GET_ALL_EVENT = "GET_ALL_EVENT";
export const CREATE_EVENT = "CREATE_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "EVENT_DELETE";
export const ADD_TO_EVENT = "ADD_TO_EVENT";
export const ACTIVE_EVENT = "ACTIVE_EVENT";


export const GET_ALL_EVENT_TYPE = "GET_ALL_EVENT_TYPE";
export const CREATE_EVENT_TYPE  = "CREATE_EVENT_TYPE ";

export const API = "API";
export const SET_PROJECT = "SET_PROJECT";