import React, { useCallback, useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  CircularProgress,
  Grid,
} from "@material-ui/core";

import TransferList from "./transferlist";
import CardTemplate from "../../../ui/cards/SimpleCard/CardTemplate";
import { workflowService } from "../../../../services/workflowservice";
import { userService } from "../../../../services/userservice";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

export default function UserWorkflow() {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState();
  const [userDetails, setUserDetails] = useState();
  const [userList, setUserList] = useState([]);
  const [isLeftLoading, setIsLeftLoading] = useState(false);
  const [isRightLoading, setIsRightLoading] = useState(false);
  const [workflowList, setWorkflowList] = useState([]);
  const [selectedWorkflowList, setSelectedWorkfowList] = useState([]);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const refetchWorkflows = async () => {
    setIsLeftLoading(true);
    const response = await workflowService.getAllWorkflow();
    setIsLeftLoading(false);

    setIsRightLoading(true);
    const userWorkflows = await userService.getUserWorkflows(selectedUser.uuid);
    setIsRightLoading(false);

    setWorkflowList(response?.workflows || []);
    setSelectedWorkfowList(userWorkflows?.results || []);
  };

  const getUserList = async (text) => {
    setIsUserLoading(true);
    const response = await userService.searchUser(text);
    setIsUserLoading(false);
    setUserList(response?.results || []);
  };

  const handleSelectUser = useCallback(async () => {
    const userDetails = await userService.getUser(selectedUser.uuid);
    setUserDetails(userDetails || null);
    refetchWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  const handleUserChange = (e) => {
    getUserList(e.target.value);
  };

  const handleAddWorkflow = async (uuid) => {
    setIsUpdateLoading(true);
    await userService.addUserWorkflow({
      user: userDetails.uuid,
      workflow: uuid,
      retired: false,
      name: "mapping",
    });
    await refetchWorkflows();
    setIsUpdateLoading(false);
  };

  const handleRemoveWorkflow = async (uuid) => {
    setIsUpdateLoading(true);
    await userService.removeUserWorkflow(uuid);
    await refetchWorkflows();
    setIsUpdateLoading(false);
  };

  useEffect(() => {
    if (selectedUser) handleSelectUser();
  }, [handleSelectUser, selectedUser]);

  return (
    <CardTemplate title="Manage User Worflow">
      <Grid container spacing={2}>
        <Grid item>
          <FormControl className={classes.formControl}>
            <Autocomplete
              options={userList}
              getOptionLabel={(option) => option.display}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    (selectedUser && userDetails?.person?.display) ||
                    "Select a User"
                  }
                  onChange={handleUserChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isUserLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              onChange={(event, newValue) => {
                setSelectedUser(newValue);
              }}
            />
          </FormControl>
        </Grid>
        {selectedUser && (
          <Grid item>
            <TransferList
              leftList={workflowList}
              rightList={selectedWorkflowList}
              leftAction={handleAddWorkflow}
              rightAction={handleRemoveWorkflow}
              isUpdateLoading={isUpdateLoading}
              isLeftLoading={isLeftLoading}
              isRightLoading={isRightLoading}
            />
          </Grid>
        )}
      </Grid>
    </CardTemplate>
  );
}
