
export const workflowconstants = {
    SHOW_WORKFLOWS : "SHOW_WORKFLOWS",
    ACTIVE_WORKFLOW: "ACTIVE_WORKFLOW",
    CREATE_WORKFLOW : "CREATE_WORKFLOW",
    UPDATE_WORKFLOW : "UPDATE_WORKFLOW",
    DELETE_WORKFLOW : "DELETE_WORKFLOW",
    ADD_TO_WORKFLOW : "ADD_TO_WORKFLOW",
    REMOVE_FROM_WORKFLOW : "REMOVE_FROM_WORKFLOW",
    GET_ALL_WORKFLOW : "GET_ALL_WORKFLOW",
};